// DEPENDECIES: ajax-upload
//
// Transforms a file field tag into an ajax uploader, which
// refreshes the specified image div upon ajax completion

(function($) {
  let i = 0;

  $.fn.imageUploader = function(options) {
    const opts = { ...$.fn.imageUploader.defaults, ...options };

    this.each(function() {
      const $fileField = $(this);
      const $container = $fileField.closest(opts.containerSelector);
      const $image = opts.$image || $container.find(opts.imageSelector);
      const $error = opts.$error || $container.find(opts.errorSelector);
      const $throbber = opts.$throbber || $container.find(opts.throbberSelector);

      const $button = $('<a/>', {
        'class': `button ${opts.buttonClass || ''}`,
        text: opts.buttonText
      });

      $fileField.replaceWith($button);

      const ajaxUpload = new AjaxUpload($button, {
        action: $fileField.attr(opts.dataKey),
        autoSubmit: true,
        name: opts.name + i++,
        responseType: 'json',
        onSubmit: (file, ext) => {
          if (!(ext && /^(jpg|png|jpeg|gif|tif|tiff)$/i.test(ext))) {
            $error.show();
            return false;
          }
          $error.hide();
          $throbber.show();
          opts.loading($container);
        },
        onComplete: function(file, response) {
          $throbber.hide();
          $container.removeClass('working');
          if (response.error) {
            opts.failure($container, $error, response);
          } else {
            opts.success($container, $image, response);
            $image.animate(
              {
                opacity: 0
              },
              {
                complete: function() {
                  let url = opts.urlProcessor(response);
                  url += (url.indexOf('?') === -1 ? '?' : '&') + '?_=' + new Date().getTime();

                  let image = $('<img/>').attr('src', url);
                  image.on('load', function() {
                    if (opts.imageIsContainer) {
                      $image
                        .empty()
                        .append($(this))
                        .animate({
                          opacity: 1
                        });
                    } else {
                      $image.attr('src', url).animate({ opacity: 1 });
                    }
                  });
                }
              }
            );
          }
        }
      });

      if ($.isPresent(opts.inputClass)) {
        $button.on('mouseover', () => {
          _.defer(() => {
            if ($.isPresent(ajaxUpload._input)) {
              $(ajaxUpload._input).addClass(opts.inputClass);
            }
          });
        });
      }
    });
    return this;
  };

  $.fn.imageUploader.defaults = {
    buttonText: $.t('controls.common.image_upload.button'),
    containerSelector: '.line',
    dataKey: 'data-endpoint',
    errorSelector: '.imageError',
    imageSelector: '.ajaxImage',
    imageIsContainer: true,
    inputClass: null,
    name: 'ajaxImageUploader',
    loading: ($container) => {
      $container.addClass('working');
    },
    success: ($container /*, $image, response*/) => {
      $container.removeClass('working');
    },
    failure: ($container, $errorContainer /*, response */) => {
      $container.removeClass('working');
      $errorContainer.show();
    },
    throbberSelector: '.uploadIndicator',
    urlProcessor: ({ id }) => `/api/assets/${id}`
  };
})(jQuery);
